*,
*::before,
*::after {
    box-sizing: border-box;
}

* {
    margin: 0;
    padding: 0;
    border: 0 solid transparent;
}

html {
    -webkit-text-size-adjust: 100%;
    scroll-behavior: smooth !important;
}

body {
    line-height: normal;
    font-family: 'Blorado', sans-serif;
    font-optical-sizing: auto;
    background-color: #282c34;
}

li,
a {
    display: inline;
    cursor: pointer;
    text-decoration: none;
    color: white;
}

#tsparticles {
    position: absolute;
    z-index: -1;
}

@keyframes showTopText {
    0% {
        transform: translate3d(0, 100%, 0);
    }
    40%,
    60% {
        transform: translate3d(0, 50%, 0);
    }
    100% {
        transform: translate3d(0, 0, 0);
    }
}
@keyframes showBottomText {
    0% {
        transform: translate3d(0, -100%, 0);
    }
    100% {
        transform: translate3d(0, 0, 0);
    }
}
.animated-title {
    position: absolute;

    height: 100%;
    width: 100%;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.animated-title > div {
    position: absolute;
    overflow: hidden;

    height: 50%;
    width: 100%;
}
.animated-title > div div,
.animated-title > div h2,
.animated-title > div.text-bottom ul {
    padding: 2vmin 0;
    position: absolute;
}

.animated-title > div.text-top {
    border-bottom: 1vmin solid white;
    top: 0;
}
.animated-title > div.text-top div {
    animation: showTopText 1s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
    bottom: 0;
    transform: translate(0, 100%);
}
.animated-title > div.text-top div h1:first-child {
    color: #767676;
}
.animated-title > div.text-bottom {
    bottom: 0;
}
.animated-title > div.text-bottom h2 {
    animation: showBottomText 0.5s;
    animation-delay: 1.75s;
    animation-fill-mode: forwards;
    top: 0;
    transform: translate(0, -100%);
}
